import type { Dispatch } from 'react'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type {
  listPaginatedOfTopicsList,
  listPaginatedOfTopicsResponse,
} from '@/store/api/NewSearch/NewSearch.types'

import { FocusTopicsSheet } from './FocusTopicsSheet'
import { TopicSwitchCard } from './TopicSwitchCard'
interface SwitchSectionTypes {
  activeSwitchesCount: number
  setActiveSwitchesCount: Dispatch<React.SetStateAction<number>>
  data?: listPaginatedOfTopicsResponse
  selectedTopics: string[]
}

export const SwitchSection = ({
  activeSwitchesCount,
  setActiveSwitchesCount,
  data,
  selectedTopics,
}: SwitchSectionTypes) => {
  const {
    i18n: { language, t },
  } = useTranslation()

  const { watch } = useFormContext()

  const formValues = watch()

  useEffect(() => {
    const activeCount = Object.values(formValues).filter(Boolean).length
    setActiveSwitchesCount(activeCount)
  }, [formValues, setActiveSwitchesCount])

  const shouldDisableSwitches = activeSwitchesCount >= 2

  const getTranslation = (
    topic: listPaginatedOfTopicsList,
    key: 'name' | 'description',
  ) => {
    const translation = topic.translations?.find(
      (t: any) => t.language === language,
    )
    return translation ? translation[key] : topic[key]
  }

  const selectedTopicsSet = new Set(selectedTopics)

  return (
    <div className="flex flex-col gap-8 mt-12">
      <div className="flex flex-col gap-2">
        <div className="flex justify-center gap-2">
          <p className="text-center text-neutralColor text-2xl">
            {t('dashboard.newSearch.focusedTopics.title')}
          </p>
          <p className="text-center text-neutralColor60 text-2xl">
            {activeSwitchesCount}/2
          </p>
        </div>

        <p className="text-center text-neutralColor80 text-base font-normal">
          {t('dashboard.newSearch.focusedTopics.subtitle1')}
          <span className="font-semibold">
            {t('dashboard.newSearch.focusedTopics.subtitle2')}
          </span>
          {t('dashboard.newSearch.focusedTopics.subtitle3')}
          <span className="font-semibold">
            {t('dashboard.newSearch.focusedTopics.subtitle4')}
          </span>
          {t('dashboard.newSearch.focusedTopics.subtitle5')}

          <span className="px-2">
            <FocusTopicsSheet data={data?.info.list} />
          </span>
        </p>
      </div>

      <div className="flex gap-6 w-full flex-wrap justify-center">
        {data?.info.list.map((topic) => (
          <TopicSwitchCard
            defaultValue={selectedTopicsSet.has(topic.name)}
            key={topic._id}
            title={getTranslation(topic, 'name')}
            formName={topic.name}
            disabled={shouldDisableSwitches}
            beta={topic.beta}
          />
        ))}
      </div>
    </div>
  )
}
