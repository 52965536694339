import React, { useState } from 'react'

import { ESocialMedias } from '@/constants/sanitizeSocialMediaConstants'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import { useSmallDesktopQuery } from '@/hooks/useSmallDesktopQuery'
import { identifySocialMedia } from '@/utils/identifySocialMedia'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import { textEllipsis } from '@/utils/strings'

import EmptyImage from '../../../assets/emptyState/emptyImage.svg'
import Clip from '../../../assets/socialMedia/Clip.svg?react'
import Facebook from '../../../assets/socialMedia/Facebook.svg?react'
import Instagram from '../../../assets/socialMedia/Instagram.svg?react'
import Linkedin from '../../../assets/socialMedia/LinkedIn.svg?react'
import Pinterest from '../../../assets/socialMedia/Pinterest.svg?react'
import Reddit from '../../../assets/socialMedia/Reddit.svg?react'
import Tiktok from '../../../assets/socialMedia/TikTok.svg?react'
import X from '../../../assets/socialMedia/X.svg?react'

interface infoDisplayItem {
  topText: string
  bottomText?: string
  imgUrl?: string
  imgAlt: string
  profileUrl: string
  chipText?: string
  socialMedia?: string
  customOnClick?: () => void
}

export const InfoDisplayItem = ({
  topText,
  bottomText,
  imgUrl,
  imgAlt,
  profileUrl,
  socialMedia,
  customOnClick,
}: infoDisplayItem) => {
  const isSmallDesktop = useSmallDesktopQuery()
  const isMobile = useMobileMediaQuery()
  const [imageSrc, setImageSrc] = useState(imgUrl)

  const SOCIAL_MEDIA_ICONS = {
    [ESocialMedias.LinkedIn]: Linkedin,
    [ESocialMedias.Instagram]: Instagram,
    [ESocialMedias.Twitter]: X,
    [ESocialMedias.Facebook]: Facebook,
    [ESocialMedias.Reddit]: Reddit,
    [ESocialMedias.Pinterest]: Pinterest,
    [ESocialMedias.TikTok]: Tiktok,
  }
  const SocialMediaIcon =
    SOCIAL_MEDIA_ICONS[identifySocialMedia(profileUrl) as ESocialMedias] || Clip

  const onPressItem = async () => {
    if (customOnClick) {
      customOnClick()
    } else if (profileUrl) {
      window.open(profileUrl, '_blank')
    }
  }

  return (
    <div
      className={
        'flex h-[72px] p-3 bg-baseColor hover:cursor-pointer justify-between items-center rounded-xl  hover:bg-primaryColor5'
      }
      onClick={onPressItem}
    >
      <div className="flex flex-row gap-3">
        {imgUrl && (
          <img
            style={{ borderRadius: '0.75rem' }}
            src={imageSrc}
            alt={imgAlt}
            className="w-12 h-12"
            onError={() => setImageSrc(EmptyImage)}
          />
        )}

        <div className="flex flex-col gap-1">
          <p className="text-neutralColor text-base font-medium">{topText}</p>
          <p className="text-neutralColor60 text-sm font-medium tracking-tight">
            {textEllipsis(
              bottomText || '',
              isMobile ? 20 : isSmallDesktop ? 35 : 60,
            )}
          </p>
        </div>
      </div>

      {socialMedia && (
        <SocialMediaIcon
          className="w-5 h-5 mr-3"
          color={weCheckPalette.neutralColor}
        />
      )}
    </div>
  )
}
