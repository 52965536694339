import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'

import { EmptyState } from '@/components/EmptyState/EmptyState'
import { Input } from '@/components/ui/input'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { useAppDispatch } from '@/hooks/redux'
import { useRedirectBasedOnPermissions } from '@/hooks/useRedirectBasedOnPermissions'
import {
  selectSelectedWorkStation,
  selectSelectedTeam,
} from '@/store/api/Users/Users.selector'
import {
  setSelectedTeamById,
  setSelectedWorkspaceById,
} from '@/store/api/Users/Users.slice'
import { useGetWorkSpaceReportsQuery } from '@/store/api/workspace/workspace.query'
import type { EReportFilter } from '@/store/api/workspace/workspace.types'

import { FaceMatchItem } from './Components/FaceMatchItem'
import { FilterChip } from './Components/FilterChip'
import {
  ReportStatusSelector,
  type ReportStatusType,
} from './Components/ReportStatusSelector'
import { ReportTypeSelector } from './Components/ReportTypeSelector'
import { SocialAnalysisItem } from './Components/SocialAnalysisItem'
import TeamSelector from './Components/TeamSelector'

export type ExtendedReportFilter = EReportFilter | 'your_reports' | undefined

export const ReportsScreen = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { hasViewReports } = useRedirectBasedOnPermissions()

  const selectedWorkStation = useSelector(selectSelectedWorkStation)
  const selectedTeam = useSelector(selectSelectedTeam)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [selectedFilter, setSelectedFilter] = useState<
    ExtendedReportFilter | 'all'
  >('all')
  const [selectedStatus, setSelectedStatus] = useState<ReportStatusType>('all')
  const pageSize = 10

  const params = new URLSearchParams(location.search)
  const subjectName = params.get('subjectName')
  let workspaceId = params.get('workspaceId')
  let teamid = params.get('teamid')

  hasViewReports()

  useEffect(() => {
    if (selectedWorkStation && workspaceId) {
      dispatch(setSelectedWorkspaceById(workspaceId))
      workspaceId = null
    }
    if (selectedTeam && teamid) {
      dispatch(setSelectedTeamById(teamid))
      teamid = null
    }
    if (subjectName) {
      setSearch(subjectName)
    }
  }, [teamid, workspaceId, subjectName, selectedWorkStation])

  const { data, isLoading, isError } = useGetWorkSpaceReportsQuery({
    id: selectedWorkStation?._id ?? '',
    teamId: selectedTeam?._id ?? '',
    page: currentPage,
    pageSize,
    reportType:
      selectedFilter === 'all'
        ? undefined
        : selectedFilter !== 'your_reports'
          ? selectedFilter
          : undefined,
    onlyUser: selectedFilter === 'your_reports',
    status:
      selectedStatus === 'all' || selectedStatus === 'new'
        ? undefined
        : selectedStatus,
    searchByString: search ? search : undefined,
  })

  // TODO: Determinar si hay más datos que cargar
  const hasMoreData = true

  const fetchMoreOnPagination = () => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  const resetFilters = () => {
    setCurrentPage(1)
  }

  const handleFilterChange = (newFilter: ExtendedReportFilter | 'all') => {
    setSelectedFilter(newFilter)
    resetFilters()
  }

  const handleStatusChange = (newStatus: ReportStatusType) => {
    setSelectedStatus(newStatus)
    resetFilters()
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    resetFilters()
  }

  return (
    <WeScreen
      isError={isError}
      isLoading={isLoading}
      useNextButton={false}
      useHeaderBackButton={false}
      childrenCustomGap={20}
      leftSideHeaderContent={<TeamSelector />}
    >
      <div className="w-full flex justify-between sm:items-center max-sm:flex-col max-sm:gap-5">
        <Input
          placeholder={t('dashboard.reportsScreen.search')}
          className="flex h-[46px] w-[354.67px] rounded-lg max-sm:w-full"
          value={search}
          useDebounce={true}
          debounceTime={600}
          onChange={handleSearch}
        />
        <div className="flex gap-5">
          <ReportTypeSelector
            selectedFilter={selectedFilter}
            onFilterChange={handleFilterChange}
          />
          <ReportStatusSelector
            selectedStatus={selectedStatus}
            onStatusChange={(value) =>
              handleStatusChange(value as ReportStatusType)
            }
          />
        </div>
      </div>
      <div className="flex gap-5 items-center">
        <FilterChip
          text={selectedStatus}
          type="status"
          isSelected={selectedStatus === 'all'}
          onClick={() => setSelectedStatus('all')}
        />
        <FilterChip
          text={selectedFilter}
          type="reportType"
          isSelected={selectedFilter === 'all'}
          onClick={() => setSelectedFilter('all')}
        />
      </div>

      <div className="flex flex-col gap-5">
        {data?.length === 0 ? (
          <EmptyState
            text={
              search === ''
                ? t('dashboard.reportsScreen.emptyState')
                : `${t('dashboard.reportsScreen.noResultsFor')} "${search}"`
            }
          />
        ) : (
          <InfiniteScroll
            className="flex flex-col gap-4"
            dataLength={data?.length || 0}
            next={fetchMoreOnPagination}
            hasMore={hasMoreData}
            loader={<p>Loading...</p>}
            height={680}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {data?.map((report) =>
              report.type === 'socialcheck' ? (
                <SocialAnalysisItem data={report.data} key={report.data.id} />
              ) : (
                <FaceMatchItem data={report.data} key={report.data._id} />
              ),
            )}
          </InfiniteScroll>
        )}
      </div>
    </WeScreen>
  )
}
