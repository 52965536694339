import { EyeOpenIcon, EyeClosedIcon } from '@radix-ui/react-icons'
import { CloseSquare, Magicpen } from 'iconsax-react'
import debounce from 'lodash/debounce'
import * as React from 'react'
import { useState, useCallback, useEffect } from 'react'

import { cn } from '@/lib/utils'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  maskFormat?: string
  showPassIcon?: boolean
  showEdditIcon?: boolean
  showDeleteIcon?: boolean
  onRemoveInput?: any
  desactive?: boolean
  icon?: any
  useDebounce?: boolean
  debounceTime?: number
  value?: string
  defaultValue?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      maskFormat,
      showPassIcon,
      desactive,
      showEdditIcon,
      icon,
      showDeleteIcon,
      onRemoveInput,
      onChange,
      useDebounce = false,
      debounceTime = 300,
      value,
      defaultValue,
      ...props
    },
    ref,
  ) => {
    const [showPass, setShowPass] = useState(false)
    const [inputValue, setInputValue] = useState(value || defaultValue || '')

    useEffect(() => {
      if (value !== undefined) {
        setInputValue(value)
      }
    }, [value])

    const debouncedOnChange = useCallback(
      debounce((value: string, event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event)
      }, debounceTime),
      [onChange, debounceTime],
    )

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value
      setInputValue(newValue)

      if (useDebounce) {
        debouncedOnChange(newValue, event)
      } else {
        onChange?.(event)
      }
    }

    const togglePasswordVisibility = () => {
      setShowPass((prevShowPass) => !prevShowPass)
    }
    return (
      <div className="flex flex-row-reverse content-end">
        <input
          type={type === 'password' ? (showPass ? 'text' : 'password') : type}
          className={cn(
            'flex h-11 w-full rounded-md border border-neutralColor30 bg-baseColorHighlight px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutralColor60 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
            className,
          )}
          value={inputValue}
          onChange={handleChange}
          ref={ref}
          {...props}
        />
        {showPassIcon && (
          <button
            className="flex absolute mt-4 mr-3"
            type="button"
            onClick={togglePasswordVisibility}
          >
            {showPass ? (
              <EyeOpenIcon
                width={18}
                height={17}
                color={weCheckPalette.neutralColor}
              />
            ) : (
              <EyeClosedIcon
                width={18}
                height={17}
                color={weCheckPalette.neutralColor}
              />
            )}
          </button>
        )}
        {icon && icon}
        {showDeleteIcon && (
          <button
            className="flex absolute mt-2.5 mr-3"
            type="button"
            onClick={onRemoveInput}
          >
            <CloseSquare
              width={18}
              height={17}
              variant="Bold"
              color={weCheckPalette.neutralColor}
            />
          </button>
        )}
        {showEdditIcon && (
          <button
            className="flex absolute mt-3 mr-3"
            type="button"
            onClick={togglePasswordVisibility}
          >
            {desactive ? (
              <Magicpen
                width={5}
                height={5}
                color={weCheckPalette.neutralColor30}
              />
            ) : (
              <Magicpen
                width={5}
                height={5}
                color={weCheckPalette.neutralColor}
              />
            )}
          </button>
        )}
      </div>
    )
  },
)
Input.displayName = 'Input'

export { Input }
