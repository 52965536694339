export enum AppRoutes {
  //Root
  home = '/',
  aboutUs = '/about-us',
  socialHome = 'https://www.wecheck.ai',
  useCases = '/use-cases',
  blog = '/blog',

  //Login
  forgotPaswordCheckYourInbox = '/login/forgot-pasword-check-your-inbox',
  login = '/login/login-form',
  forgotPassword = '/login/forgot-password',
  createPassword = '/login/create-password',

  //SignUp
  registerStart = '/sign-up/sign-up-start',
  registerEmailVerification = '/sign-up/email-verification',
  registerFirstStep = '/sign-up/first-step',
  registerSecondStep = '/sign-up/second-step',
  registerFinalStep = '/sign-up/final-step',
  inviteFirstStep = '/sign-up/invite-first-step',
  inviteSecondStep = '/sign-up/invite-second-step',
  invitationExpired = '/sign-up/invitation-expired',

  //Dashboard
  feedBackScreen = '/dashboard/feedback-screen',
  dashboard = '/dashboard/dashboard-home',
  contactUs = '/dashboard/contact-us',
  aiGeneratedReport = '/dashboard/ai-generated-report',
  lab = '/dashboard/lab',
  biling = '/dashboard/biling',
  getCredits = '/dashboard/get-credits',
  feedbackPayment = '/dashboard/feedback-payment',
  historyPlan = '/dashboard/history-plan',
  reports = '/dashboard/reports',
  enterpriseReports = '/dashboard/enterprise-reports',
  enterprise = '/dashboard/enterprise',
  teamSelection = '/dashboard/team-selection',
  roleGuide = '/dashboard/role-guide',
  yourReports = '/dashboard/your-reports',
  FaceMatchResults = '/dashboard/face-match-results',
  account = '/dashboard/settings',
  faceSearch = '/dashboard/face-search',
  reportGeneralInfo = '/dashboard/report-general-info',
  postList = '/dashboard/post-list',
  socialMediaPostList = '/dashboard/social-media-post-list',
  workSpaceCreation = '/dashboard/work-space-creation',
  workSpaceSettings = '/dashboard/work-space-settings',
  workSpaceCreationSecondStep = '/dashboard/work-space-creation-second-step',
  workSpaceCreationThirdStep = '/dashboard/work-space-creation-third-step',
  newSearchInitialReportSetup = '/dashboard/new-search-initial-report-setup',
  newSearchConsent = '/dashboard/new-search-consent',
  newSearchFocusedTopics = '/dashboard/new-search-focused-topics',
  newSearchFormSubjectInfo = '/dashboard/new-search-subject-info',
  newSearchCompanyInfo = '/dashboard/new-search-company-info',
  newSearchLegalDisclaimer = '/dashboard/new-search-legal-disclaimer',
  reportInProgress = '/dashboard/report-in-progress',
  searchResults = '/dashboard/search-results',
  advancedSearch = '/dashboard/advanced-search',
  generatingReport = '/dashboard/generating-report',
  AIReportFocusedTopics = '/dashboard/focused-topics',
  createTeamFirstStep = '/dashboard/create-team-first-step',
  createTeamSecondStep = '/dashboard/create-team-second-step',
}
