import { zodResolver } from '@hookform/resolvers/zod'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import type { z } from 'zod'

import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import {
  setCompanyInfoStepGuardToken,
  setSubjectInfoStepGuardToken,
} from '@/store/api/NewSearch/NewSeachGuardToken.slice'
import { useListPaginatedOfTopicsQuery } from '@/store/api/NewSearch/NewSearch.query'
import {
  selectNewSearch,
  selectNewSearchFirstStep,
} from '@/store/api/NewSearch/NewSearch.selector'
import { setFirstStep } from '@/store/api/NewSearch/NewSearch.slice'
import { switchSectionSchema } from '@/utils/schemas'

import { DefaultBehaviorFlags } from './Components/DefaultBehaviorFlags'
import { Stepper } from './Components/Stepper'
import { SwitchSection } from './Components/SwitchsSection'
import { useHasCurrentStepToken } from './Guards/useNewSearchSecondStepGuard'

export const NewSearchFocusedTopics = () => {
  useHasCurrentStepToken('firstStep')

  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isCompanySearch =
    useSelector(selectNewSearch).useCases.isForCompanySearch
  const searchType = useSelector(selectNewSearch).selectSearchType.searchType
  const selectedTopics = useSelector(selectNewSearchFirstStep)

  const steps = isCompanySearch
    ? [
        searchType === 'ai_report' || searchType === 'adverse_media_report'
          ? undefined
          : t('dashboard.newSearch.common.focusedTopics'),
        t('dashboard.newSearch.common.companyInformation'),
        t('dashboard.newSearch.common.submit'),
      ]
    : [
        searchType === 'adverse_media_report'
          ? undefined
          : t('dashboard.newSearch.common.focusedTopics'),
        t('dashboard.newSearch.common.subjectInformation'),
        t('dashboard.newSearch.common.submit'),
      ]
  const activeStep = 0

  const [activeSwitchesCount, setActiveSwitchesCount] = useState(0)
  const { data, isLoading, isError } = useListPaginatedOfTopicsQuery({
    page: 1,
    pageSize: 1000,
  })

  const defaultValues: any = data?.info.list.reduce(
    (acc, topic) => {
      acc[topic.name] = selectedTopics.includes(topic.name)
      return acc
    },
    {} as { [key: string]: boolean },
  )

  const formMethods = useForm<z.infer<typeof switchSectionSchema>>({
    resolver: zodResolver(switchSectionSchema),
    defaultValues: defaultValues,
  })

  const onSubmit = formMethods.handleSubmit((formData) => {
    const selectedTopics =
      data?.info.list
        .filter((topic) => formData[topic.name])
        .map((topic) => ({
          id: topic._id,
          name: topic.name,
        })) || []

    dispatch(
      setFirstStep({
        topics: selectedTopics.map((topic) => topic.name),
        topicsIds: selectedTopics.map((topic) => topic.id),
      }),
    )
    if (isCompanySearch) {
      dispatch(
        setCompanyInfoStepGuardToken({ companyInfoStepGuardToken: true }),
      )

      navigate(AppRoutes.newSearchCompanyInfo)
    } else {
      dispatch(
        setSubjectInfoStepGuardToken({ subjectInfoStepGuardToken: true }),
      )

      navigate(AppRoutes.newSearchFormSubjectInfo)
    }
  })

  return (
    <FormProvider {...formMethods}>
      <WeScreen
        isLoading={isLoading}
        isError={isError}
        onClickNextButton={onSubmit}
      >
        <div className="flex flex-col items-center h-full p-6 gap-2">
          <div className="flex">
            <Stepper steps={steps} activeStep={activeStep} />
          </div>
          <div className="flex flex-col justify-center h-full">
            <DefaultBehaviorFlags />

            <SwitchSection
              data={data}
              activeSwitchesCount={activeSwitchesCount}
              setActiveSwitchesCount={setActiveSwitchesCount}
              selectedTopics={selectedTopics}
            />
          </div>
        </div>
      </WeScreen>
    </FormProvider>
  )
}
