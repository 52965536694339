export interface SearchPersonResponse {
  list: SearchPersonItem[]
  results: number
}

export interface SearchPersonItem {
  firstname: string
  lastname: string
  age: number
  endato_id: string
  address: string
}

export interface SearchPersonRequest {
  fullName: string
  city: string
  alias: string
  state: string
  country: string
  dob: string
  email: string
  phoneNumber: string
  company: string
  collage: string
  highSchool: string
  facebook: string
  instagram: string
  linkedIn: string
  x: string
  tiktok: string
  topics: string[]
  analyzePeopleWithAnalyst: boolean
  pdfCoreFindings: boolean
  pdfFlaggedBehaviors: boolean
  pdfFocusedTopics: boolean
  pdfLanguage: string
  pdfNegativePosts: boolean
  pdfNeutralPosts: boolean
  pdfPositivePosts: boolean
  pdfSentimentAnalysis: boolean
  pdfSocialMediaAccounts: boolean
  pdfWebSearchPosts: boolean
  topicsIds: string[]
  fcra: boolean
}

export interface SearchPersonCrudResponse {
  message: string
  status: number
  info: SearchPersonResponse
}

interface IPersonHouseAddress {
  number: string
  name: string
  type: string
  city: string
  state: string
  zip: string
  zip4: string
  lat: string
  lng: string
  full: string
}

interface ICriminalBody {
  hair: string | null
  eyes: string | null
  height: string | null
  weight: string | null
  sex: string | null
  race: string | null
  photo: string | null
}

interface ICriminalLocation {
  city: string | null
  state: string | null
  zip: string
}

interface ICriminalSource {
  state: string
  name: string
}

interface ICriminalCharge {
  offenseDate: string | null
  offenseDescr: string | null
  court: string | null
  arrestDate: string | null
  arrestingAgency: string | null
  disposition: string | null
  dispositionDate: string | null
}

interface ICriminalSensitiveReferences {
  name: string
  id: string
}

export interface ICriminalSensitive {
  category: string
  references: ICriminalSensitiveReferences[]
  summary: string
  photos: string[]
}

interface IBgCheckCriminal {
  category: string
  caseNumber: string
  body: ICriminalBody
  location: ICriminalLocation
  source: ICriminalSource
  charges: ICriminalCharge[]
}

interface IBgCheckDateRange {
  start: string | null
  end: string | null
}

interface IBgCheckEducation {
  degree: string
  school: string
  date_range: IBgCheckDateRange
}

interface ICompanyLocation {
  country: string
  address: string | null
  geo: string
}

interface ICompanySocialMedia {
  linkedIn: string | null
  facebook: string | null
  twitter: string | null
  website: string | null
}

interface IJobCompany {
  name: string
  employees: string | null
  industry: string | null
  location: ICompanyLocation | null
  socialMedia: ICompanySocialMedia
}

interface IJobExperience {
  position: string
  start: string
  finish: string | null
  company: IJobCompany
}

export interface IBgCheckJob {
  lastUpdate: string
  skills: string[]
  experience: IJobExperience[]
}

interface IPersonEmail {
  address: string
  type?: string
}

interface ILanguage {
  region?: string | null
  language: string
  display: string
}

interface IPersonPhoneNumber {
  number: string
  type: string
  firstReport: string
  lastReport: string
}

export interface IBgCheckSocialMediaElement {
  socialMedia: string
  type: string
  url: string
}

interface IBgCheckVehicle {
  vin: string
  year: string
  make: string
  model: string
  vehicle_type?: string
}

export interface IBgCheckPersonal {
  endatoId: string
  fullName: string
  firstName: string
  middleName: string
  lastName: string
  age: number
  dob: string
  placeOfBirth: string
  gender: string
  nationality: string
  citizenship: string
  recommendedEmail: string | null
  akas: string[]
  lastHouseAddress: IPersonHouseAddress
  addressHistory: IPersonHouseAddress[]
  lastPhoneNumber: IPersonPhoneNumber
  phoneNumbersHistory: IPersonPhoneNumber[]
  languages: ILanguage[]
  emails: IPersonEmail[]
  matchRate: number
}

interface IEvictionPersonAddress {
  number: string
  street: string
  type: string
  city: string
  state: string
  zip: string
  zip4: string
  lat: string
  lng: string
  full: string
}

interface IEvictionPerson {
  endatoId: string | null
  firstName: string
  middleName: string
  lastName: string
  address?: IEvictionPersonAddress
}

interface IEvictionCourt {
  type: string
  id: string
  name: string
  phone: string
  address: IEvictionPersonAddress
}

interface IBgCheckEviction {
  caseNumber: string
  state: string
  liabilityAmount: string
  assetsInDollar: string
  reportDate: string
  defendant: IEvictionPerson
  plaintiff: IEvictionPerson
  court: IEvictionCourt
}

export interface IBgPropertyAddress {
  number: string

  name: string

  type: string

  city: string

  state: string

  zip: string

  zip4: string

  lat: string

  lng: string

  propertyType: string

  full: string
}

export interface IBgPropertyAssessedValue {
  price: number

  year: string
}

export interface IBgPropertyOwner {
  fullName: string

  isCorporationOrBusiness?: boolean

  endatoId: null | string

  transactionDate: string
}

export interface IBgPropertyPurchasePrice {
  price: number

  date: string
}
export interface IBgCheckProperties {
  assesorParcelNumber: string

  purchasePrice: IBgPropertyPurchasePrice

  assessedValue: IBgPropertyAssessedValue

  currentOwners: IBgPropertyOwner[]

  address: IBgPropertyAddress

  priceHistory: IBgPropertyPurchasePrice[]
}

export interface IBgCheckBusiness {
  name: string

  titles: string[]

  fullAddress: string

  licenseType: string

  filingNumber: string

  filingDate: string

  isHighConfidence: boolean
}

export interface IDebtPersonInvolved {
  type: string
  isBusiness: boolean
  firstName: string
  middleName: string
  lastName: string
  email: string
  phone: string
  businessName: string
  fullName: string
  address: IPersonHouseAddress[]
}

export interface IDebtCaseJudge {
  firstName: string
  middleName: string
  lastName: string
  full: string
}

export interface IDebtCourt {
  id: string
  division: string
  district: string
  name: string
  phone: string
  email: string
  url: string
  address: IPersonHouseAddress
  judge: IDebtCaseJudge
}

export interface IDebtCase {
  number: string
  filingDate: string
  assets: string
  reportDate: string
  originalDepartment: string
  fileState: string
  lawFirm: string
  closedDate: string
  reopenedDate: string
  assetsAvailable: string
  assetValue: string
  amountOrLiability: string
  plaintiffLawFirm: string
  attorney: string
  liabilityAmount: string
  liability: string
  interestRate: string
}
export interface IBgCheckDebt {
  type: string

  filingDate: string

  reportDate: string

  peopleInvolved: IDebtPersonInvolved[]

  courts: IDebtCourt[]

  caseDetails: IDebtCase[]
}

export interface BackgroundCheckResponse {
  personal: IBgCheckPersonal
  vehicles: IBgCheckVehicle[]
  education: IBgCheckEducation[]
  socialMedias: IBgCheckSocialMediaElement[]
  job: IBgCheckJob
  criminal: IBgCheckCriminal[]
  evictions: IBgCheckEviction[]
  sensitive: ICriminalSensitive
  properties: IBgCheckProperties[]
  business: IBgCheckBusiness[]
  debt?: IBgCheckDebt[]
  userId: string
}

export interface BackgroundCheckCrudBEResponse {
  message: string
  status: number
  info: BackgroundCheckResponse
}

export interface BackgroundCheckRequest {
  id: string | null
}

export interface PersonHistoryCrudBeResponse {
  message: string
  status: number
  info: PersonHistoryResponse
}

export interface IBgCheckData {
  id: string

  firstName: string

  middleName: string

  lastName: string

  createdAt: string

  age: number

  report: string

  status: 'init' | 'pending' | 'completed' | 'opened'
}

export interface BackgroundCheckPdfRequest {
  id: string
}

export interface BackgroundCheckPdfResponse {
  message: string
  status: number
  info: {
    report: string
  }
}
export type PersonHistoryResponse = SocialHistoryItem[]

export interface ISocialCheckInput {
  name: string
  nickname: string
  city?: string
  state: string
  country: string
  email: string
  highSchool: string
  college: string
  phone: string
  twitterUrl: string
  facebookUrl: string
  instagramUrl: string
  linkedInUrl: string
  tikTokUrl: string
  ageOrDateOfBirth: string
}
export enum ESocialCheckStatus {
  None = 'None',
  InQueue = 'InQueue',
  InProgress = 'InProgress',
  Complete = 'Complete',
  Error = 'Error',
  PendingDownloadPdf = 'PendingDownloadPdf',
}

export interface SocialHistoryItem {
  inputs: ISocialCheckInput
  id: string
  pdf: string
  pdfViewed: boolean
  reportDate: string
  status: ESocialCheckStatus
}

export interface ReDowloadPdfResponse {
  info: {
    url: string
  }
}

export interface summary {
  executiveAim: string
  executiveSummary: string
  personalEngagement: string
  personalSentiment: string
  strategyDiversity: string
  strategyThemes: string
  strategyResponse: string
  digitalBehavior: string
  digitalInfluence: string
  recommendationsInsights: string
  recommendationsImprovements: string
  recommentationsConclusion: string
  riskIndicators: string
  sentimentAnalysis: string
  redFlags: string
  webNewsFindings: string
}

export interface Source {
  [key: string]: boolean | undefined
}

export interface Inputs {
  ageOrDateOfBirth: string
  city: string
  college: string
  country: string
  email: string
  facebookUrl: string
  highSchool: string
  instagramUrl: string
  linkedInUrl: string
  name: string
  nickname: string
  phone: string
  state: string
  tikTokUrl: string
  twitterUrl: string
  topics: string[]
}

export interface Risk {
  count: number
  name: string
}

export interface ChannelsValue {
  channel: string
  posts: number
  flagged: number
}

export interface IDate {
  year: number
  month: number
  day: number
}
export interface IEducation {
  startDate: IDate
  endDate: IDate
  title: string
  grade: string
  school: string
  description: string
  schoolUrl: string
  fieldOfStudy: string
}

export interface ICareer {
  startDate: IDate
  endDate: IDate
  title: string
  company: string
  companyLogoUrl: string
  companyLinkedinURL: string
  description: string
  companyStaffCountRange: string
}

export interface ISkills {
  name: string
  passedSkillAssessment: boolean
  endorsementsCount: number
}

export interface IDigitalFootprints {
  url: string
  description: string
  userName: string
  platform: string
  image: string
  status: string
}
export interface getSubjectInfo {
  fullName: string
  followers: number
  following: number
  posts: number
  score: number
  summary: summary
  sources: Source[]
  socialMedia: SocialMediaAccount[]
  profileImgUrl: string
  inputs: Inputs
  flaggedPosts: number
  positivePosts: number
  negativePosts: number
  webSearchPosts: number
  risks: Risk[]
  channelsValue: ChannelsValue[]
  pdf: string
  complianceSigned: string
  disclosureSigned: string
  summarySigned: string
  dynamicSigned: string
  otherAuthorizationSigned: string
  workEmails: string[]
  personalEmails: string[]
  phones: string[]
  address: string[]
  career: ICareer[]
  education: IEducation[]
  skills: ISkills[]
  digitalFootprints: IDigitalFootprints[]
  pwnedBreaches: IEmailBreach
}
export interface IEmailBreach {
  email: string
  breaches: IPwnedBreach[]
}
export interface IPwnedBreach {
  Name: string
  Title: string
  Domain: string
  BreachDate: string
  AddedDate: string
  ModifiedDate: string
  PwnCount: number
  Description: string
  LogoPath: string
  DataClasses: string[]
  IsVerified: boolean
  IsFabricated: boolean
  IsSensitive: boolean
  IsRetired: boolean
  IsSpamList: boolean
  IsMalware: boolean
  IsSubscriptionFree: boolean
  IsStealerLog: boolean
}
export interface SocialMediaAccount {
  id: string
  name: string
  profileImgUrl: string
  userName: string
  profileLink: string
  bio?: string
  followers: number
  following: number
  postsCount: number
  isPrivate: boolean
  isVerified: boolean
  flaggedCount: number
  scrapeStatus: string
  profileImgKey: string
}
export interface getSubjectBasicInformationResponse {
  message: string
  status: number
  info: getSubjectInfo
}

export interface getSubjectBasicInformationRequest {
  id: string
}

export interface ISocialMediaPostResponse {
  name: string
  userName: string
  profileLink: string
  followers: number
  following: number
  postsCount: number
  isPrivate: boolean
  posts: SocialMediaPostPaginated
  profileImgUrl: string
}

export interface SocialMediaPostPaginated {
  page: number
  size: number
  pages: number
  offset: number
  totalItems: number
  currentItems: number
  list: SocialMediaPost[]
}

export interface SocialMediaPost {
  type: string
  profileImgUrl: string
  link: string
  content: string
  date: string
  sentiment: number
  sentimentFlag: string
  flaged: boolean
  flagedText: string
  mediaUrl?: string
  videoUrl?: string
  flags: string[]
  sentimentReason: string
  flagReason: string
  platform: string
}

export type PlatformType =
  | 'LinkedIn'
  | 'Facebook'
  | 'Instagram'
  | 'Pinterest'
  | 'TikTok'
  | 'Twitter'
  | 'Reddit'
  | 'WebSearch'

export enum PlatformTypes {
  LINKEDIN = 'LinkedIn',
  FACEBOOK = 'Facebook',
  INSTAGRAM = 'Instagram',
  PINTEREST = 'Pinterest',
  TIKTOK = 'TikTok',
  TWITTER = 'Twitter',
  REDDIT = 'Reddit',
  WEBSEARCH = 'WebSearch',
}

export type FilterByType = 'Positive' | 'Negative' | 'Neutral' | 'Flagged'

export enum FilterByTypeEnum {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
  NEUTRAL = 'Neutral',
  FLAGGED = 'Flagged',
  TOPIC = 'Topic',
  WEBNEWS = 'Web/News',
}

export interface ISocialMediaPostRequest {
  id: string
  page: number
  pageSize: number
  plataform?: PlatformType
  filterBy?: FilterByType
  socialMediaId?: string
}

export interface CancelReportRequest {
  id: string
}

export interface CancelReportResponse {
  message: string
  status: number
}
