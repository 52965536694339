import { CloseSquare, SearchNormal1 } from 'iconsax-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { AlertDialog, AlertDialogContent } from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import type {
  EUserRole,
  EUserRoleSelector,
} from '@/hooks/usePermissions/permissions.type'
import useSmallMobileMediaQuery from '@/hooks/useSmallMobileMediaQuery'
import {
  useDeleteInvitationUserMutation,
  useUpdateEnterpriseUserRoleMutation,
} from '@/store/api/Enterprise/Enterprise.query'
import {
  useInviteMemberToATeamMutation,
  useRemoveMemberFromTeamMutation,
} from '@/store/api/workspace/workspace.query'
import type {
  MemberToAssign,
  UserData,
  Workspace,
  WorkspaceTeam,
} from '@/store/api/workspace/workspace.types'
import { getColorByName } from '@/utils/colorByName'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'
import type { inviteMemberValidationType } from '@/utils/schemas'

import { DobleAvatarPlusNameCard } from './DobleAvatarPlusNameCard'
import { EditInviteCard, EInviteAction } from './EditInviteCard'
import { EditMemberCard } from './EditMemberCard'
import { InviteMemberForm } from './InviteMemberForm'
import { MemberItem } from './MemberItem'
import { PopUpTopHeader } from './PopUpTopHeader'

export const ManageTeam = ({
  isOpen,
  setIsOpen,
  team,
  workspace,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  team: WorkspaceTeam
  workspace: Workspace | undefined
}) => {
  const { t } = useTranslation()
  const { members, name: teamName } = team || {}

  const isMobile = useMobileMediaQuery()
  const isSmallMobile = useSmallMobileMediaQuery()

  const [showMore, setShowMore] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const [selectedUser, setSelectedUser] = useState<UserData | null>(null)
  const [inviteAction, setInviteAction] = useState<EInviteAction | null>(
    EInviteAction.RESEND,
  )
  const [selectedRole, setSelectedRole] = useState<
    EUserRoleSelector | 'remove' | null
  >(null)

  const [inviteMemberToATeam, { isLoading: isInvitingMember }] =
    useInviteMemberToATeamMutation()

  const [updateMemberRole, { isLoading: isUpdatingMemberRole }] =
    useUpdateEnterpriseUserRoleMutation()

  const [removeMemberFromTeam, { isLoading: isRemovingMember }] =
    useRemoveMemberFromTeamMutation()

  const [revokeInvite, { isLoading: isRevokingInvite }] =
    useDeleteInvitationUserMutation()

  const handleRoleChange = (role: EUserRoleSelector) => {
    setSelectedRole(role)
  }

  const handleInviteChange = (invite: EInviteAction) => {
    setInviteAction(invite)
  }

  const handleShowMore = () => {
    setShowMore(!showMore)
  }

  const closeModal = () => {
    setIsOpen(false)
    setShowMore(false)
    setSearchTerm('')
    setSelectedUser(null)
    setSelectedRole(null)
  }

  const handleInviteMember = async (data: inviteMemberValidationType) => {
    try {
      const memberData: MemberToAssign = {
        email: data.email,
        role: data.role,
        workspaces: [
          {
            workspaceId: workspace?._id ?? '',
            teamIds: [team._id],
          },
        ],
      }

      await inviteMemberToATeam(memberData).unwrap()
      toast.success(t('dashboard.company.manageModal.inviteSuccess'))
      closeModal()
    } catch (e) {
      if (e.data?.code === 'EMAIL_DUP') {
        toast.error(t('dashboard.company.manageModal.userIsAlreadyInvited'))
      } else if (e.data?.code === 'USER_NOT_AVAILABLE') {
        toast.error(t('dashboard.company.manageModal.userNotAvailable'))
      } else {
        toast.error(t('dashboard.company.manageModal.errorSendingInvitation'))
      }
    }
  }

  const handleRemoveMember = async (userId: string) => {
    try {
      await removeMemberFromTeam({
        workspaces: [
          {
            workspaceId: workspace?._id ?? '',
            teamIds: [team._id],
          },
        ],
        memberId: userId,
      }).unwrap()

      toast.success(t('dashboard.company.manageModal.memberRemoved'))
    } catch (error) {
      catchWithSentry(error)
      toast.error(t('dashboard.company.manageModal.errorRemovingMember'))
    }
  }

  const handleUpdateMemberRole = async () => {
    if (!selectedUser?.user) return

    try {
      await updateMemberRole({
        id: selectedUser?.user?._id ?? '',
        role: selectedRole ?? '',
      }).unwrap()
      toast.success(t('dashboard.company.manageModal.memberRoleUpdated'))
    } catch (error) {
      catchWithSentry(error)
      toast.error(t('dashboard.company.manageModal.memberRoleUpdatedError'))
    }
  }

  const handleTeamAssignment = async () => {
    if (!selectedUser?.userEmail) {
      toast.error(t('dashboard.company.manageModal.emailRequired'))
      return
    }

    try {
      const memberData: MemberToAssign = {
        email: selectedUser.userEmail,
        role: selectedUser.user?.role ?? '',
        workspaces: [
          {
            workspaceId: workspace?._id ?? '',
            teamIds: [team._id],
          },
        ],
      }

      await inviteMemberToATeam(memberData).unwrap()
      toast.success(t('dashboard.company.manageModal.memberTeamUpdated'))
    } catch (error) {
      catchWithSentry(error)
      toast.error(t('dashboard.company.manageModal.memberTeamUpdatedError'))
    }
  }

  const handleSaveEditMember = async () => {
    if (selectedRole && selectedRole !== selectedUser?.user?.role) {
      if (selectedRole === 'remove') {
        await handleRemoveMember(selectedUser?._id ?? '')
      } else {
        await handleUpdateMemberRole()
      }
    }

    await handleTeamAssignment()
    setSelectedUser(null)
    setSelectedRole(null)
  }

  const handleEditMember = (user: UserData) => {
    setSelectedUser(user)
    setShowMore(true)

    if (user?.user?.role) {
      setSelectedRole(user.user.role as EUserRoleSelector)
    }
  }

  const handleSaveEditInvite = async () => {
    if (inviteAction === EInviteAction.REVOKE) {
      try {
        await revokeInvite({
          inviteMemberId: selectedUser?.invite?.inviteId ?? '',
          workspaceId: workspace?._id ?? '',
          teamId: team._id,
          userId: selectedUser?._id ?? '',
        }).unwrap()
        toast.success(t('dashboard.company.manageModal.inviteRevoked'))
        closeModal()
      } catch (error) {
        catchWithSentry(error)
        toast.error(t('dashboard.company.manageModal.inviteRevokedError'))
      }
    }

    if (inviteAction === EInviteAction.RESEND) {
      await revokeInvite({
        inviteMemberId: selectedUser?.invite?.inviteId ?? '',
        workspaceId: workspace?._id ?? '',
        teamId: team._id,
        userId: selectedUser?._id ?? '',
      }).unwrap()

      await handleInviteMember({
        email: selectedUser?.userEmail ?? '',
        role: selectedUser?.invite?.role as EUserRole,
      })
    }
  }

  const filterMembers = (user: UserData) => {
    const { user: userData, userEmail } = user
    const firstName = userData?.firstname ?? ''
    const lastName = userData?.lastname ?? ''

    const fullName = `${firstName} ${lastName}`

    return (
      fullName.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      userEmail.toLowerCase().includes(searchTerm?.toLowerCase())
    )
  }

  const isLoading =
    isInvitingMember ||
    isUpdatingMemberRole ||
    isRemovingMember ||
    isRevokingInvite

  const nameShowInOthers =
    members?.[2]?.user?.firstname ?? members?.[2]?.userEmail
  const nameForInitials =
    members?.[3]?.user?.firstname ?? members?.[3]?.userEmail

  const showMoreMembers =
    !selectedUser && !showMore && members?.length && members?.length > 2
  const showPreviewMembers = showMore && !selectedUser

  return (
    <AlertDialog open={isOpen} onOpenChange={closeModal}>
      <AlertDialogContent
        className={`flex flex-col gap-3 ${
          isMobile ? 'w-[350px]' : isSmallMobile ? 'w-[250px]' : 'w-[500px]'
        }`}
      >
        <div className="flex flex-1 flex-col gap-3">
          {/* Modal Home */}
          {!showMore && (
            <>
              <div className="flex flex-row justify-between items-center">
                <p className="text-base font-bold">{teamName}</p>

                <div className="flex flex-row justify-end items-center border-neutralColor60">
                  <Button
                    onClick={closeModal}
                    variant="link"
                    className="text-base"
                  >
                    <CloseSquare
                      width={18}
                      height={17}
                      variant="Bold"
                      color={weCheckPalette.neutralColor}
                    />
                  </Button>
                </div>
              </div>

              {/* Invite Member */}
              <div className="flex flex-col justify-between gap-4">
                <div>
                  <p className="text-neutralColor60 text-sml">
                    {t('dashboard.company.manageModal.memberWillJoinThisTeam')}
                  </p>
                </div>
                <InviteMemberForm
                  onInvite={handleInviteMember}
                  isLoading={isInvitingMember}
                />
              </div>

              {/* Preview Members */}
              {members?.length ? (
                <div className="flex flex-col gap-4">
                  <div>
                    <p className="text-neutralColor60 text-sml">
                      {t('dashboard.company.manageModal.whoHasAccess')}
                    </p>
                  </div>

                  {members?.slice(0, 2).map((user) => (
                    <MemberItem
                      key={`${user.userEmail}`}
                      firstname={user.user?.firstname ?? ''}
                      lastname={user.user?.lastname ?? ''}
                      invite={user.invite}
                      email={user.userEmail}
                      role={user.user?.role ?? ''}
                      color={getColorByName(
                        user.user?.firstname ?? user.userEmail,
                        user.user?.lastname ?? '',
                      )}
                      onClick={() => {
                        setSelectedUser(user)
                        setShowMore(true)
                      }}
                    />
                  ))}
                </div>
              ) : null}
            </>
          )}

          {/* Show more members */}
          {showMoreMembers ? (
            <div className="flex flex-1 flex-col">
              <button
                onClick={handleShowMore}
                className="flex flex-1 text-neutralColor"
              >
                {showMore ? (
                  ''
                ) : (
                  <div className="flex flex-1 my-4">
                    <DobleAvatarPlusNameCard
                      nameShowInOthers={nameShowInOthers}
                      nameForInitials={nameForInitials}
                      countElements={members.length}
                      rightIcon
                    />
                  </div>
                )}
              </button>
            </div>
          ) : null}

          {/* View list of all members */}
          {showPreviewMembers && (
            <div className="flex flex-1 flex-col">
              <PopUpTopHeader
                text={`${members.length} ${t(
                  'dashboard.company.manageModal.colaboratorsTeam',
                )}`}
                onClick={() => {
                  setShowMore(false)
                  setSearchTerm('')
                }}
                setIsOpen={closeModal}
              />

              <Input
                placeholder={t('dashboard.company.search')}
                className="h-[46px] rounded-lg w-full p-4 mb-2 mt-2"
                icon={
                  <SearchNormal1
                    className="absolute mt-6 mr-4"
                    size="18"
                    color={weCheckPalette.neutralColor30}
                  />
                }
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <div className="flex flex-1 flex-col max-h-[330px] overflow-y-auto gap-3 mt-4 scrollbar-thin scrollbar-thumb-neutralColor60 scrollbar-track-transparent">
                {team?.members.filter(filterMembers).map((user, index) => (
                  <div key={`${user._id}-member-item-${index}`}>
                    <MemberItem
                      key={`${user._id}-${index}`}
                      firstname={user.user?.firstname ?? user.userEmail}
                      invite={user.invite}
                      lastname={user.user?.lastname ?? ''}
                      email={user.userEmail}
                      role={user.user?.role ?? ''}
                      onClick={() => handleEditMember(user)}
                      color={getColorByName(
                        user.user?.firstname ?? user.userEmail,
                        user.user?.lastname ?? '',
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Edit Selected User View*/}
          {selectedUser && (
            <div className="flex flex-1 flex-col gap-4">
              {!selectedUser?.invite ? (
                <>
                  <PopUpTopHeader
                    text={t('dashboard.company.manageModal.memberSettings')}
                    onClick={() => setSelectedUser(null)}
                    setIsOpen={closeModal}
                  />

                  <EditMemberCard
                    role={selectedUser.user?.role as EUserRole}
                    firstname={selectedUser.user?.firstname ?? ''}
                    lastname={selectedUser.user?.lastname ?? ''}
                    email={selectedUser.userEmail}
                    color={getColorByName(
                      selectedUser.user?.firstname ?? selectedUser.userEmail,
                      selectedUser.user?.lastname ?? '',
                    )}
                    onRoleChange={handleRoleChange}
                  />

                  <div className="flex flex-1 flex-col justify-end">
                    <div className="flex flex-row gap-3 justify-end border-t-[0.5px] border-neutralColor60 pt-3">
                      <Button
                        variant="secondary"
                        onClick={() => setSelectedUser(null)}
                        disabled={isLoading}
                      >
                        {t('dashboard.company.manageModal.cancel')}
                      </Button>
                      <Button
                        variant="default"
                        onClick={handleSaveEditMember}
                        isLoading={isLoading}
                      >
                        {t('dashboard.company.manageModal.save')}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <PopUpTopHeader
                    text={t('dashboard.company.editInvite.inviteSettings')}
                    onClick={() => setSelectedUser(null)}
                    setIsOpen={closeModal}
                  />

                  <EditInviteCard
                    user={selectedUser}
                    inviteAction={inviteAction ?? EInviteAction.RESEND}
                    onInviteChange={handleInviteChange}
                    invite={selectedUser.invite}
                  />

                  <div className="flex flex-1 flex-col justify-end">
                    <div className="flex flex-row gap-3 justify-end border-t-[0.5px] border-neutralColor60 pt-3">
                      <Button
                        variant="secondary"
                        onClick={() => setSelectedUser(null)}
                        disabled={isLoading}
                      >
                        {t('dashboard.company.manageModal.cancel')}
                      </Button>
                      <Button
                        variant="default"
                        onClick={handleSaveEditInvite}
                        isLoading={isLoading}
                      >
                        {t('dashboard.company.manageModal.save')}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
