import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '@/store/store'

import type { UserInfoResponse } from './Users.types'

export const selectUser = (user: UserInfoResponse | undefined) => user

export const selectUserInfo = (state: RootState) => state.userInfo

export const selectPermissions = (state: RootState) =>
  state.userInfo.permissions

export const selectRole = (state: RootState) => state.userInfo.role

export const selectItsDefaultState = (state: RootState) =>
  state.userInfo.email === ''

export const selectIsPending = createSelector(
  selectUser,
  (user?: UserInfoResponse) => user?.status === 'pending',
)

export const selectUserFullname = createSelector(
  selectUser,
  (user?: UserInfoResponse) => `${user?.firstname} ${user?.lastname}`,
)

export const selectEmail = createSelector(
  selectUser,
  (user?: UserInfoResponse) => `${user?.email}`,
)
export const selectOrgName = createSelector(
  selectUser,
  (user?: UserInfoResponse) => `${user?.enterprise.name}`,
)

export const selectPhoneNumber = createSelector(
  selectUser,
  (user?: UserInfoResponse) => `${user?.phoneNumber}`,
)

export const selectWorkspaceCreditsFromStore = (state: RootState) =>
  state.userInfo.workspaceCredits

export const selectEnterpriseCreditsFromStore = (state: RootState) =>
  state.userInfo?.enterprise?.credits

export const selectUserFirstLetter = createSelector(
  selectUser,
  (user?: UserInfoResponse) => {
    if (user?.firstname) {
      return user.firstname[0]
    } else if (user?.lastname[0]) {
      return user?.lastname[0]
    }
    return ''
  },
)

export const selectWorkStations = (state: RootState) =>
  state.userInfo.workStations

export const selectSelectedWorkStation = (state: RootState) =>
  state.userInfo.selectedWorkStation

export const selectTeamsForSelectedWorkStation = createSelector(
  selectWorkStations,
  selectSelectedWorkStation,
  (workStations, selectedWorkStation) =>
    workStations.find((ws) => ws._id === selectedWorkStation?._id)?.teams ?? [],
)

export const selectSelectedTeam = (state: RootState) =>
  state.userInfo.selectedTeam

export const selectFrom = (state: RootState) => state.userInfo.from

export const selectHasMinorSearch = (state: RootState) =>
  state.userInfo.hasMinorSearch

export const selectEnterprise = (state: RootState) => state.userInfo.enterprise

export const selectTeamsbyWorkspaceId = (
  state: RootState,
  workspaceId: string | null,
) =>
  state.userInfo.workStations.find((w) => w._id === workspaceId)?.teams ?? null

export const selectWorkspacebyId = (
  state: RootState,
  workspaceId: string | null,
) => state.userInfo.workStations.find((w) => w._id === workspaceId) ?? null

export const selectTeambyId = (
  state: RootState,
  workspaceId: string | null,
  teamId: string | null,
) => {
  const workspace =
    state.userInfo.workStations.find((w) => w._id === workspaceId) ?? null
  if (!workspace) {
    return null
  }
  return workspace.teams.find((t) => t._id === teamId)
}
