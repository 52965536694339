import React from 'react'

import { Avatar, AvatarImage } from '@/components/ui/avatar'
import type { FaceCheckOutputGroup } from '@/store/api/workspace/workspace.types'
interface FaceMatchAvatarsProps {
  items: FaceCheckOutputGroup[]
}
export const FaceMatchAvatars: React.FC<FaceMatchAvatarsProps> = ({
  items,
}) => {
  return (
    <div className="flex flex-row items-center h-10">
      {items.map((image) =>
        image.map((avatar) => (
          <Avatar key={avatar.guid} className={`relative ${blur}`}>
            <AvatarImage src={avatar.base64} />
          </Avatar>
        )),
      )}
    </div>
  )
}
