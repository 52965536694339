import type {
  EReportType,
  ESocialCheckStatus,
} from '../Enterprise/Enterprise.types'

export interface WorkspaceTeam {
  _id: string
  name: string
  members: UserData[]
}

export interface Workspace {
  _id: string
  name: string
  teams: WorkspaceTeam[]
  allMembers: UserData[]
}
interface User {
  _id: string
  role: string
  firstname: string
  lastname: string
}

export interface UserData {
  _id: string
  userEmail: string
  user: User | null
  invite: InviteData | null
}

export interface InviteData {
  inviteId: string
  inviteStatus: string
  lastInviteUpdate: string
  role: string
}

export interface WorkspaceCredits {
  aiReportCredits: number
  aiAnalystReportCredits: number
  adverseMediaReportCredits: number
  assistedReportCredits: number
  facialMatchingCredits: number
}

export interface WorkspaceDetailsResponse {
  credits: WorkspaceCredits
  _id: string
  name: string
  teams: WorkspaceTeam[]
  allMembers: UserData[]
}

export interface WorkspaceCreditsResponse {
  credits: WorkspaceCredits
}

export interface WorkspaceDetailsRequest {
  workspaceId: string
}

export enum EReportFilter {
  Ai = 'ai_report',
  AiAnalyst = 'ai_analyst_report',
  AdverseMedia = 'adverse_media_report',
  Assited = 'assisted_report',
  FacialMatching = 'facial_matching',
}

export interface getWorkSpaceReportsRequest {
  id: string
  teamId: string
  page: number
  pageSize: number
  reportType?: EReportFilter
  status?: ESocialCheckStatus
  onlyUser?: boolean
  searchByString?: string
}

export interface getAllWorkSpaceReportsRequest {
  teamId: string | null
  workspaceId: string | null
  page: number
  pageSize: number
  reportType?: EReportFilter
  status?: ESocialCheckStatus
  onlyUser?: boolean
  searchByString?: string
}

export enum EReportDataType {
  SocialCheck = 'socialcheck',
  FaceCheck = 'facecheck',
}

export type ReportInfo = SocialCheckInfo | FaceCheckInfo

// Estructura para respuestas de tipo "socialcheck"
export interface SocialCheckInfo {
  type: 'socialcheck'
  data: ReportCheckData
}

// Estructura para respuestas de tipo "facecheck"
export interface FaceCheckInfo {
  type: 'facecheck'
  data: FaceCheckData
}

// Datos específicos de "socialcheck"
export interface ReportCheckData {
  id: string
  status: string
  inputs: SubjectInputs
  pdfLanguage: string
  pdfCoreFindings: boolean
  pdfSocialMediaAccounts: boolean
  pdfSentimentAnalysis: boolean
  pdfFlaggedBehaviors: boolean
  pdfPositivePosts: boolean
  pdfNegativePosts: boolean
  pdfNeutralPosts: boolean
  pdfWebSearchPosts: boolean
  pdfFocusedTopics: boolean
  reportDate: string // ISO Date String
  pdfViewed: boolean
  pdf: string | null
  subjectId: string
  profileImgUrl: string
  hiddenDigitalReport: boolean
  requester: Requester
  enterprise: Enterprise
  reportType: EReportType
  complianceStatus: string
  lastUpdate: string // ISO Date String
  complianceSigned: string | null
  disclosureSigned: string | null
  summarySigned: string | null
  dynamicSigned: string | null
  otherAuthorizationSigned: string | null
  wecheckScrapped: boolean
  workspace: string
  team: string
  signedAt: string | null // ISO Date String
  createdAt: string // ISO Date String
  updatedAt: string // ISO Date String
}

// Inputs específicos del sujeto en "socialcheck"
export interface SubjectInputs {
  name: string
  nickname: string | null
  lastName: string
  redditUrl: string | null
  middleName: string | null
  city: string | null
  state: string | null
  country: string | null
  email: string
  highSchool: string | null
  college: string | null
  phone: string | null
  company: string | null
  twitterUrl: string | null
  facebookUrl: string | null
  instagramUrl: string | null
  linkedInUrl: string | null
  tikTokUrl: string | null
  pinterestUrl: string | null
  ageOrDateOfBirth: string | null
  topics: string[]
  website: string | null
  industry: string | null
  stateLong: string | null
  countryLong: string | null
  cityShort: string | null
  cityLong: string | null
  stateShort: string | null
  countryShort: string | null
}

// Datos específicos de "facecheck"
export interface FaceCheckData {
  _id: string
  idSearch: string
  message: string
  progress: string
  error: string | null
  code: string | null
  output: FaceCheckOutputGroup[]
  input: FaceCheckInput[]
  requester: FaceCheckRequester
  enterprise: string
  workspace: string
  team: string
  createdAt: string
  updatedAt: string
}

export type FaceCheckOutputGroup = FaceCheckOutput[]

export interface FaceCheckOutput {
  guid: string
  score: number
  group: number
  base64: string
  url: string
  index: number
  indexDB: number
  seen: string | null
}

export interface FaceCheckInput {
  base64: string
  id_pic: string
  url_source: string | null // URL fuente (si existe)
  svg_anim: string | null // Animación SVG (si existe)
  idPic: string // Identificador adicional de la imagen
}

// Requester del "facecheck"
export interface FaceCheckRequester {
  email: string
  fullName: string
}

export interface Requester {
  id: string
  firstname: string
  lastname: string
  email: string
}

export interface Enterprise {
  id: string
}

export interface getWorkSpaceReportsResponse {
  message: string
  info: ReportInfo[]
}

export interface getWorkSpaceReportsCrudresponse {
  message: string
  status: number
  info: ReportInfo[]
}

export interface GetWorkspacesReponse {
  credits: WorkspaceCredits
  workspace: Workspace[]
}

export interface WorkSpaceInfo {
  _id: string
  name: string
  teams: WorkspaceTeam[]
  credits: WorkspaceCredits
  allMembers: UserData[]
}

export interface WorkSpaceResponse {
  message: string
  info: {
    workspaces: WorkSpaceInfo[]
    allMembers: UserData[]
  }
  status: number
}

export interface AssignCreditsToWorkspaceRequest {
  workspaceId: string
  values: AssignCreditsValue[]
}

export interface AssignCreditsValue {
  quantity: number
  type: EReportType
}
export interface UpdateTeamData {
  id: string
  teamId: string
  name: string | undefined
}

export interface UpdateWorkspaceData {
  id: string
  name: string | undefined
}

export interface MemberToAssign {
  email: string
  role: string
  workspaces: {
    workspaceId: string
    teamIds: string[]
  }[]
}

export interface AssignMembersRequest {
  members: MemberToAssign[]
}

export interface WorkspaceTeams {
  workspaceId: string
  teamIds: string[]
}

export interface RemoveMemberFromTeamRequest {
  workspaces: WorkspaceTeams[]
  memberId: string
}
