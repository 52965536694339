import { ReloadIcon } from '@radix-ui/react-icons'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/lib/utils'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-xl text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-primaryColor tracking-widest text-staticWhite shadow hover:bg-primaryColor60',
        destructive:
          'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        outline:
          'border border-primaryColor30 bg-transparent shadow-sm hover:bg-accent hover:text-accent-foreground hover:cursor-pointer select-none',
        secondary:
          'bg-primaryColor5 rounded-xl border border-primaryColor30 hover:bg-primaryColor30',
        ghost: 'hover:bg-accent text-neutralColor hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        blue: 'bg-cyan-100 rounded-xl text-cyan-700 text-base font-medium',
      },
      size: {
        default: 'h-9 px-4 py-2',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-10 rounded-md px-8',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  isLoading?: boolean
}

const ButtonLoading = ({
  classname,
  variant,
  size,
  children,
}: {
  classname: string | undefined
  variant?: ButtonProps['variant']
  size?: ButtonProps['size']
  children: React.ReactNode
}) => {
  return (
    <Button
      disabled
      className={cn('relative', classname)}
      variant={variant}
      size={size}
    >
      <span className="opacity-0">{children}</span>
      <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <ReloadIcon className="h-4 w-4 animate-spin" />
      </span>
    </Button>
  )
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      isLoading,
      disabled,
      onClick,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    if (isLoading) {
      return (
        <ButtonLoading
          classname={className}
          variant={variant}
          size={size}
          children={props.children}
        />
      )
    }

    const clickCallback = disabled ? undefined : onClick
    return (
      <Comp
        className={`${cn(buttonVariants({ variant, size, className }))} ${
          disabled
            ? 'bg-primaryColor5 !text-neutralColor30 border border-neutralColor30 hover:bg-primaryColor5'
            : ''
        }`}
        ref={ref}
        onClick={clickCallback}
        disabled={disabled}
        {...props}
      />
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
