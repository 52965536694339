import LanguageDetector from 'i18next-browser-languagedetector'
import React from 'react'
import { useSelector } from 'react-redux'

import RoleGuideDarkEnglish from '@/assets/roleGuideDarkEnglish.png'
import RoleGuideDarkSpanish from '@/assets/roleGuideDarkSpanish.png'
import RoleGuideLightEnglish from '@/assets/roleGuideLightEnglish.svg?react'
import RoleGuideLightSpanish from '@/assets/roleGuideLightSpanish.png'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { selectIsDarkMode } from '@/store/api/ui/ui.selector'

export const RoleGuideScreen = () => {
  const theme = useSelector(selectIsDarkMode)

  const languageDetector = new LanguageDetector(null, {
    convertDetectedLanguage: (lng) => {
      if (lng.includes('es')) {
        return 'es'
      }

      return 'en'
    },
  })

  return (
    <WeScreen title={'Role Guide'} useNextButton={false} useHeaderBackButton>
      <div className="w-full">
        {theme ? (
          languageDetector.detect() === 'es' ? (
            <img
              src={RoleGuideDarkSpanish}
              alt="Role Guide"
              className="w-full"
              style={{ maxHeight: 'calc(100vh - 100px)', objectFit: 'contain' }}
            />
          ) : (
            <img
              src={RoleGuideDarkEnglish}
              alt="Role Guide"
              className="w-full"
              style={{ maxHeight: 'calc(100vh - 100px)', objectFit: 'contain' }}
            />
          )
        ) : languageDetector.detect() === 'es' ? (
          <img
            src={RoleGuideLightSpanish}
            alt="Role Guide"
            className="w-full"
            style={{ maxHeight: 'calc(100vh - 100px)', objectFit: 'contain' }}
          />
        ) : (
          <RoleGuideLightEnglish
            className="w-full"
            style={{ maxHeight: 'calc(100vh - 100px)', objectFit: 'contain' }}
          />
        )}
      </div>
    </WeScreen>
  )
}
