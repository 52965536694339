import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import type { listPaginatedOfTopicsList } from '@/store/api/NewSearch/NewSearch.types'

import { UseCaseCard } from './UseCaseCard'

export const FocusTopicsSheet = ({
  data,
}: {
  data?: listPaginatedOfTopicsList[]
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const isMobile = useMobileMediaQuery()

  const getTranslation = (
    topic: listPaginatedOfTopicsList,
    key: 'name' | 'description',
  ) => {
    const translation = topic.translations?.find(
      (t: any) => t.language === language,
    )
    return translation ? translation[key] : topic[key]
  }

  return (
    <Sheet>
      <SheetTrigger>
        <p className="text-dynamicBlue text-center text-base font-medium text-nowrap ml-1">
          {t(
            'dashboard.newSearch.focusedTopics.focusTopicSheet.learnMoreFocusTopics',
          )}
        </p>
      </SheetTrigger>

      <SheetContent
        className="flex flex-col gap-8 max-h-full overflow-y-auto rounded-tl-2xl px-4"
        side={isMobile ? 'bottom' : 'right'}
      >
        <SheetHeader className="flex flex-col gap-3 mt-[-6px]">
          <SheetTitle className="text-textNormal text-xl font-medium tracking-wide text-left">
            {t('dashboard.newSearch.focusedTopics.focusTopicSheet.sheetTitle')}
          </SheetTitle>
          <SheetDescription className="max-md:text-left">
            {t(
              'dashboard.newSearch.focusedTopics.focusTopicSheet.sheetDescription',
            )}
          </SheetDescription>
        </SheetHeader>

        <div className="flex flex-col gap-8 justify-center">
          {data?.map((topic) => (
            <UseCaseCard
              key={topic._id}
              title={getTranslation(topic, 'name')}
              description={getTranslation(topic, 'description')}
            />
          ))}
        </div>
      </SheetContent>
    </Sheet>
  )
}
