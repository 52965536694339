import { ArrowDown2 } from 'iconsax-react'
import { useSelector } from 'react-redux'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  selectTeambyId,
  selectTeamsbyWorkspaceId,
} from '@/store/api/Users/Users.selector'
import type { RootState } from '@/store/store'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

const EnterpriseTeamSelector = ({
  setSelectedOfficeAndTeam,
  selectedOfficeAndTeam,
}: {
  setSelectedOfficeAndTeam: ({
    workspace,
    team,
  }: {
    workspace: string | null
    team: string | null
  }) => void
  selectedOfficeAndTeam: {
    workspace: string | null
    team: string | null
  }
}) => {
  const teams = useSelector((state: RootState) =>
    selectTeamsbyWorkspaceId(state, selectedOfficeAndTeam.workspace),
  )
  const selectedTeam = useSelector((state: RootState) =>
    selectTeambyId(
      state,
      selectedOfficeAndTeam.workspace,
      selectedOfficeAndTeam.team,
    ),
  )

  const handleTeamSelect = (teamId: string) => {
    if (teamId === 'all') {
      setSelectedOfficeAndTeam({
        workspace: selectedOfficeAndTeam.workspace,
        team: null,
      })
    } else {
      if (teamId) {
        setSelectedOfficeAndTeam({
          workspace: selectedOfficeAndTeam.workspace,
          team: teamId,
        })
      }
    }
  }

  return (
    <div className="flex w-full justify-center">
      <Select
        value={
          selectedOfficeAndTeam.team === null
            ? 'all'
            : selectedOfficeAndTeam.team
        }
        onValueChange={handleTeamSelect}
      >
        <SelectTrigger
          className="w-min gap-3 items-center justify-center border-none bg-transparent focus:ring-0 focus:ring-offset-0 text-xl font-medium"
          hiddenArrow
        >
          <SelectValue
            placeholder={
              selectedOfficeAndTeam.team === null ? 'All' : selectedTeam?.name
            }
          />
          <div className="rounded-full bg-baseColor justify-center items-center p-[2px]">
            <ArrowDown2 size="17" color={weCheckPalette.neutralColor30} />
          </div>
        </SelectTrigger>
        <SelectContent>
          <SelectItem key="all" value="all">
            All Teams
          </SelectItem>

          {teams?.map((team) => (
            <SelectItem key={team._id} value={team._id}>
              {team.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}

export default EnterpriseTeamSelector
