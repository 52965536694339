import { useState } from 'react'

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import { useSmallDesktopQuery } from '@/hooks/useSmallDesktopQuery'
import { textEllipsis } from '@/utils/strings'

import EmptyImage from '../../../assets/emptyState/emptyImage.svg'

interface pwndBreachesItem {
  topText: string
  bottomText?: string
  imgUrl?: string
  imgAlt: string
  breachDate: string
  description?: string
}

export const PwndBreachesItem = ({
  topText,
  bottomText,
  imgUrl,
  imgAlt,
  breachDate,
  description,
}: pwndBreachesItem) => {
  const isSmallDesktop = useSmallDesktopQuery()
  const isMobile = useMobileMediaQuery()
  const [imageSrc, setImageSrc] = useState(imgUrl)

  return (
    <div
      className={
        'flex h-[72px] p-3 bg-baseColor justify-between items-center rounded-xl'
      }
    >
      <HoverCard>
        <HoverCardTrigger className="w-full">
          <div className="flex flex-row gap-3 w-full">
            {imgUrl && (
              <img
                style={{ borderRadius: '0.75rem' }}
                src={imageSrc}
                alt={imgAlt}
                className="w-12 h-12"
                onError={() => setImageSrc(EmptyImage)}
              />
            )}
            <div className="flex justify-between w-full items-center">
              <div className="flex flex-col gap-1">
                <p className="text-neutralColor text-base font-medium">
                  {topText}
                </p>
                <p className="text-neutralColor60 text-sm font-medium tracking-tight">
                  {textEllipsis(
                    bottomText || '',
                    isMobile ? 20 : isSmallDesktop ? 35 : 60,
                  )}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-neutralColor60 text-sm font-medium tracking-tight">
                  {breachDate}
                </p>
              </div>
            </div>
          </div>
        </HoverCardTrigger>
        <HoverCardContent
          className={
            isMobile ? 'w-[300px]' : isSmallDesktop ? 'w-[450px]' : 'w-[600px]'
          }
        >
          <div
            dangerouslySetInnerHTML={{ __html: description || '' }}
            className="text-neutralColor60 text-sm font-medium tracking-tight"
          />
        </HoverCardContent>
      </HoverCard>
    </div>
  )
}
