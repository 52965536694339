import React from 'react'

import { Card } from '@/components/ui/card'
import { FormControl, FormField, FormItem } from '@/components/ui/form'
import { Switch } from '@/components/ui/switch'

interface SwitchCardTypes {
  title: string
  formName: string
  disabled?: boolean
  checked?: boolean
  defaultValue?: boolean
  beta?: boolean
}

export const TopicSwitchCard = ({
  title,
  formName,
  disabled,
  defaultValue,
  beta,
}: SwitchCardTypes) => {
  return (
    <Card className="w-[31%] max-lg:w-[47%] max-md:w-full rounded-2xl p-1.5">
      <FormField
        name={formName}
        render={({ field }) => (
          <FormItem className="flex flex-col p-[6.5px] justify-start items-start gap-2 rounded-lg space-y-0 w-full">
            <div className="flex justify-between w-full">
              <div className="flex flex-row gap-3">
                <p
                  className={`${
                    disabled ? 'text-neutralColor30' : 'text-neutralColor'
                  } text-base font-medium w-full`}
                >
                  {title}
                </p>
                {beta && (
                  <div className="flex items-center h-6 px-3 text-cyan-700 text-xs bg-cyan-100 rounded-[200px]">
                    BETA
                  </div>
                )}
              </div>

              <FormControl>
                <Switch
                  defaultChecked={defaultValue}
                  className="w-12 h-6"
                  checked={field.value}
                  onCheckedChange={(checked) => {
                    field.onChange(checked)
                  }}
                  disabled={!field.value && disabled}
                />
              </FormControl>
            </div>

            <p
              className={`${
                disabled ? 'text-neutralColor30' : 'text-neutralColor60'
              } text-sm font-medium tracking-tight`}
            ></p>
          </FormItem>
        )}
      />
    </Card>
  )
}
