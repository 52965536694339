export interface translation {
  name: string
  language: string
  description: string
  keywords: string[]
}

export interface listPaginatedOfTopicsList {
  _id: string
  name: string
  description: string
  isActive: boolean
  enterprise: string
  prompt: string
  translations: translation[]
  beta: boolean
}

export interface listPaginatedOfTopicsInfo {
  page: number
  size: number
  pages: number
  offset: number
  totalItems: number
  currentItems: number
  list: listPaginatedOfTopicsList[]
}

export interface listPaginatedOfTopicsResponse {
  message: string
  status: number
  info: listPaginatedOfTopicsInfo
}

export interface listPaginatedOfTopicsRequest {
  page?: number
  pageSize?: number
  enterprise?: string
  status?: string
}

interface useCasesTranslations {
  description: string
  language: string
  name: string
}

export interface listOfUseCasesInfo {
  _id: string
  name: string
  description: string
  requiredComplianceForm: boolean
  translations: useCasesTranslations[]
  default: boolean
  isForCompanySearch: boolean
}
export interface listOfUseCasesResponse {
  info: listOfUseCasesInfo[]
}

export interface sendEmailWithComplianceDocumentRequest {
  email: string
  documentToSend: string
  businessDocument: string
  id: string
}

export interface startNewPersonSearchRequest {
  workspaceId: string
  teamId: string

  name?: string
  middleName?: string
  lastName?: string
  nickname?: string

  city?: string
  state?: string
  country?: string

  email?: string
  college?: string
  phone?: string
  company?: string

  twitterUrl?: string
  facebookUrl?: string
  instagramUrl?: string
  tikTokUrl?: string
  linkedInUrl?: string
  pinterestUrl?: string
  redditUrl?: string

  ageOrDateOfBirth?: string
  picture?: string

  pdfCoreFindings: boolean
  pdfFlaggedBehaviors: boolean
  pdfFocusedTopics: boolean
  pdfLanguage: string
  pdfNegativePosts: boolean
  pdfNeutralPosts: boolean
  pdfPositivePosts: boolean
  pdfSentimentAnalysis: boolean
  pdfSocialMediaAccounts: boolean
  pdfWebSearchPosts: boolean

  topics?: string[]
  topicsIds?: string[]
  reportType: string
  useCase: string
  optionalCompliance: boolean

  website?: string
  industry?: string

  cityShort?: string
  stateShort?: string
  countryShort?: string
  cityLong?: string
  stateLong?: string
  countryLong?: string
}

export interface statsInfo {
  completedReports: number
  pendingReports: number
  faceSearchReports: number
}
export interface getStatsResponse {
  info: statsInfo
}

export interface findSubjectRequest {
  page: number
  linkedinUrl: string
  firstName?: string
  lastName?: string
  company?: string
  school?: string
  reportType: string
}

export interface findSubjectLocation {
  country: string
  city: string
  full: string
}

export interface findSubjectItem {
  firstName: string
  lastName: string
  headline: string
  profilePicture: string
  location: findSubjectLocation
  profileURL: string
  alias: string
  company: string
  collage: string
}
export interface findSubjectData {
  page: number
  count: number
  pages: number
  items: findSubjectItem[]
}
export interface findSubjectResponse {
  status: number
  message: string
  info: findSubjectData
}

export enum ESocialMedia {
  LINKEDIN = 'LinkedIn',
  FACEBOOK = 'Facebook',
  INSTAGRAM = 'Instagram',
  PINTEREST = 'Pinterest',
  TIKTOK = 'TikTok',
  TWITTER = 'Twitter',
  REDDIT = 'Reddit',
  WEBSEARCH = 'WebSearch',
}

export enum EReportStatus {
  Searching = 'Searching',
  Scraping = 'Scraping',
  IaAnalysis = 'IaAnalysis',
  Completed = 'Completed',
}

export interface getStatusData {
  progress: number
  status: EReportStatus
  currenScraping: ESocialMedia
}

export interface getStatusResponse {
  status: number
  message: string
  info: getStatusData
}

export interface getStatusRequest {
  subjectId: string
}
