import { zodResolver } from '@hookform/resolvers/zod'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useAppSelector } from '@/hooks/redux'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import {
  EUserRole,
  ETraductionKeysByUserRole,
  EUserRoleSelector,
  EUserPermission,
} from '@/hooks/usePermissions/permissions.type'
import { usePermissions } from '@/hooks/usePermissions/usePermissions'
import { selectRole } from '@/store/api/Users/Users.selector'
import type { inviteMemberValidationType } from '@/utils/schemas'
import { inviteMemberValidation } from '@/utils/schemas'

interface InviteMemberFormProps {
  onInvite: (data: inviteMemberValidationType) => Promise<void>
  isLoading?: boolean
}

export const InviteMemberForm = ({
  onInvite,
  isLoading,
}: InviteMemberFormProps) => {
  const { t } = useTranslation()
  const { hasPermission } = usePermissions()
  const userRole = useAppSelector(selectRole)

  const availableRoles = useMemo(() => {
    return Object.values(EUserRoleSelector).filter((role) =>
      hasPermission(
        userRole === EUserRole.WORKSPACE_ADMIN
          ? EUserPermission.HANDLE_MEMBERS
          : EUserPermission.HANDLE_USERS,
        role as unknown as EUserRole,
      ),
    )
  }, [hasPermission, userRole])

  const form = useForm<inviteMemberValidationType>({
    resolver: zodResolver(inviteMemberValidation),
    defaultValues: {
      email: '',
      role: availableRoles[0] as unknown as EUserRole,
    },
  })

  const { handleSubmit, control } = form

  const onSubmit = async (data: inviteMemberValidationType) => {
    try {
      await onInvite(data)
      form.reset()
    } catch (e) {
      catchWithSentry(e)
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-row w-full gap-4 items-center"
      >
        <div className="flex flex-row w-full gap-4 rounded-md border border-neutralColor30 bg-baseColorHighlight">
          <FormField
            control={control}
            name="email"
            render={({ field }) => (
              <FormItem className="w-full select-none">
                <FormControl>
                  <Input
                    {...field}
                    placeholder={t('dashboard.team.email')}
                    className="w-full border-none select-none"
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="role"
            render={({ field }) => (
              <FormItem>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <SelectTrigger>
                    <SelectValue>
                      <p className="mr-2">
                        {t(ETraductionKeysByUserRole[field.value as EUserRole])}
                      </p>
                    </SelectValue>
                  </SelectTrigger>

                  <SelectContent>
                    {Object.values(availableRoles).map((role) => (
                      <SelectItem key={role} value={role}>
                        {t(ETraductionKeysByUserRole[role])}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
        </div>

        <Button type="submit" disabled={isLoading}>
          {t('dashboard.company.manageModal.invite')}
        </Button>
      </form>
    </Form>
  )
}
