import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import { Checkbox } from '@/components/ui/checkbox'
import { FormItem } from '@/components/ui/form'
import type {
  WorkSpaceInfo,
  Workspace,
} from '@/store/api/workspace/workspace.types'

export const OfficeCard = ({
  workspace,
  onSelectedValueChange,
  onUnselectedValueChange,
  disabledCheckBoxes = false,
}: {
  workspace: WorkSpaceInfo | Workspace
  onSelectedValueChange: (value: any) => void
  onUnselectedValueChange: (value: any) => void
  disabledCheckBoxes?: boolean
}) => {
  const { watch, setValue } = useFormContext()
  const { getValues } = useFormContext()
  const { t } = useTranslation()

  const selectedTeams = watch(`teams.${workspace._id}`) || []
  const getTotalSelectedTeams = () => {
    const formValues = getValues('teams')
    return Object.values(formValues).reduce(
      (total: number, workspaceTeams: any) => {
        return total + (workspaceTeams?.length || 0)
      },
      0,
    )
  }

  const handleCheckboxChange = (
    team: any,
    checked: boolean | 'indeterminate',
  ) => {
    const teamWithWorkspaceId = { workspaceId: workspace._id, id: team._id }
    const totalSelectedTeams = getTotalSelectedTeams()

    if (
      !checked &&
      totalSelectedTeams === 1 &&
      selectedTeams.includes(team._id)
    ) {
      toast.error(t('dashboard.company.manageModal.mustHaveOneTeam'))
      return
    }

    if (checked !== 'indeterminate' && checked) {
      setValue(`teams.${workspace._id}`, [...selectedTeams, team._id])
      onSelectedValueChange(teamWithWorkspaceId)
    } else {
      setValue(
        `teams.${workspace._id}`,
        selectedTeams.filter((id: string) => id !== team._id),
      )
      onUnselectedValueChange(teamWithWorkspaceId)
    }
  }

  const handleSelectAllChange = (checked: boolean) => {
    const allTeamIds = workspace.teams.map((team) => team._id)
    const totalSelectedTeams = getTotalSelectedTeams()

    if (!checked && totalSelectedTeams === selectedTeams.length) {
      toast.error(t('dashboard.company.manageModal.mustHaveOneTeam'))
      return
    }

    setValue(`teams.${workspace._id}`, checked ? allTeamIds : [])

    workspace.teams.forEach((team) => {
      const teamData = {
        workspaceId: workspace._id,
        id: team._id,
      }
      if (checked) {
        onSelectedValueChange(teamData)
      } else {
        onUnselectedValueChange(teamData)
      }
    })
  }

  return (
    <div className="flex flex-col gap-2">
      <p className="text-neutralColor text-sm">{workspace.name}</p>
      <div className="flex flex-col gap-1 ml-3">
        <FormItem className="flex items-center gap-2">
          <Checkbox
            checked={selectedTeams.length === workspace.teams.length}
            onCheckedChange={handleSelectAllChange}
            disabled={disabledCheckBoxes}
          />
          <p className="text-neutralColor text-sm pb-1.5">Select All</p>
        </FormItem>

        {workspace.teams.map((team) => (
          <FormItem key={team._id} className="flex items-center gap-2">
            <Checkbox
              checked={selectedTeams.includes(team._id)}
              onCheckedChange={(checked) => handleCheckboxChange(team, checked)}
              disabled={disabledCheckBoxes}
            />
            <p className="text-neutralColor text-sm pb-1.5">{team.name}</p>
          </FormItem>
        ))}
      </div>
    </div>
  )
}
